import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { Model, addListToDropdown, createDropdown } from '@ckeditor/ckeditor5-ui';
import { Collection, Locale } from '@ckeditor/ckeditor5-utils';
import icon from '../../icones/shortcut.svg';

export default class Atalhos extends Plugin {
    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add( 'atalhos', () => {

            const locale = new Locale();
            const items = new Collection();
    
            const dropdown = createDropdown(locale);
            
            dropdown.buttonView.set({
                withText: false,
                label: 'Atalhos',
                icon: icon,
                tooltip: true
            });

            fetch(`${global.gConfig.url_base_exames}/laudo/atalho`, { headers: { authorization: `Bearer ${localStorage.getItem('stt-authorization')}` } })
            .then(response => response.json())
            .then(data => {
                data.forEach(item => {
                    items.add({
                        type: 'button',
                        model: new Model({
                            withText: true,
                            label: item.atalho,
                            text: item.texto
                        })
                    });
                })
                addListToDropdown(dropdown, items);
            })
            .catch(err => console.log(err));

            dropdown.on('execute', (event) => {
                const { text } = event.source;

                editor.model.change( writer => {
                    const insertPosition = editor.model.document.selection.getFirstPosition();
                    writer.insertText(text, insertPosition);
                });
            });

            dropdown.render();
            dropdown.panelView.element.style = 'max-height: 200px; overflow-y: auto;';
            return dropdown;
        });
    }
}